
import { Vue, Component } from "vue-property-decorator";
import debounce from "debounce";
import store from "@/store";
import CompanyService from "@/services/company-service";
import Company from "@/types/company";
import Profile from "@/types/profile";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

interface CompanyItem {
  id: number;
  name: string;
}

interface CompaniesData {
  items: Array<CompanyItem>;
  total: number;
}

@Component
export default class SelectCompany extends Vue {
  companyService: CompanyService;
  companiesData: CompaniesData;
  companiesPage: number;
  companiesLimit: number;
  companiesSearch: string | null;
  loading: boolean;

  selectedCompany: CompanyItem | null;

  constructor() {
    super();

    this.companyService = CompanyService.getInstance();
    this.companiesData = { items: [], total: 0 };
    this.companiesPage = 1;
    this.companiesLimit = 10;
    this.companiesSearch = null;
    this.loading = false;

    this.selectedCompany = null;
    this.handleSelectCompany(null);
  }

  mounted() {
    this.fetchMoreCompanies({});
  }

  async endIntersect(_entries: any, _observer: any, isIntersecting: boolean) {
    if (isIntersecting) {
      await this.fetchMoreCompanies({});
    }
  }

  get fetchMoreCompanies() {
    return debounce(async ({ reset }: { reset?: boolean }) => {
      await this._fetchMoreCompanies({ reset });
    }, 1000);
  }

  async _fetchMoreCompanies({ reset }: { reset?: boolean }) {
    if (reset) {
      this.companiesPage = 1;
    }

    const thereAreMoreCompaniesToFetch =
      this.companiesPage === 1 ||
      this.companiesData.total > this.companiesData.items.length;
    if (thereAreMoreCompaniesToFetch) {
      this.loading = true;

      const [error, newCompaniesDataRaw] =
        await this.companyService.listCompanies({
          page: this.companiesPage,
          limit: this.companiesLimit,
          sort: "name:ASC",
          search: this.companiesSearch ? this.companiesSearch : undefined
        });

      if (!error) {
        const newCompaniesData: CompaniesData = {
          ...newCompaniesDataRaw!,
          // only keep necessary properties from fetched Companies
          items: newCompaniesDataRaw!.items.map((c: Company) => ({
            id: c.id,
            name: c.name
          }))
        };

        // Add new items to existing list
        this.companiesData = {
          items: reset
            ? newCompaniesData.items
            : [...this.companiesData.items, ...newCompaniesData.items],
          total:
            reset || this.companiesData.total === 0
              ? newCompaniesData.total
              : this.companiesData.total
        };

        const userHasSelectedACompany = !!this.selectedCompany;
        if (!userHasSelectedACompany) {
          if (this.companiesData.total === 1) {
            // If user hasn't selected any company and only has one company,
            // that company should be selected
            this.selectedCompany = { ...this.companiesData.items[0] };
          } else {
            // If user hasn't selected any company but has multiple companies,
            // we just select the "default" company
            this.selectedCompany = null;
          }
        } else {
          if (
            userHasSelectedACompany &&
            !this.companiesData.items.find(
              (c) => c.id === this.selectedCompany?.id
            )
          ) {
            // If user has already selected a company but that company is not in
            // the updated list, we de-select it
            this.selectedCompany = null;
          } else {
            // Otherwise, we just keep the current company selected
          }
        }

        this.handleSelectCompany(this.selectedCompany);

        this.companiesPage += 1;
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      this.loading = false;
    }
  }

  handleSelectCompany(selectedCompany: CompanyItem | null) {
    const companyId = selectedCompany ? selectedCompany.id : null;
    if (companyId) {
      const user = store.getters["auth/authenticatedUser"];
      if (user.type === "EMPLOYEE") {
        const profile: Profile = user.profiles.find(
          (profile: Profile) => profile.companyId === companyId
        );
        const permissions = profile.permissions.map(
          (permission) => permission.name
        );
        store.commit("auth/setUserPermissions", permissions);
      }
    }

    store.commit("auth/setCurrentUserCompanyId", companyId);
  }

  // Required due to bug (see https://github.com/vuetifyjs/vuetify/issues/12880)
  // Couldn't simply use :search-input.sync
  async _asyncSetSearch(value: any) {
    await this.$nextTick();
    this.companiesSearch = value;
  }
}
