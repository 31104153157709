var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.companiesData)?_c('v-autocomplete',{staticClass:"mx-md-12",attrs:{"return-object":"","items":_vm.companiesData.items,"item-value":"id","item-text":"name","search-input":_vm.companiesSearch,"no-filter":"","loading":_vm.loading,"label":"Selecione a empresa","prepend-inner-icon":"mdi-magnify","dense":"","flat":"","solo-inverted":"","hide-no-data":"","hide-details":""},on:{"update:search-input":(value) => {
      _vm.companiesSearch = null;
      _vm._asyncSetSearch(value);

      if (!_vm.selectedCompany || value !== _vm.selectedCompany.name) {
        _vm.fetchMoreCompanies({ reset: true });
      }
    },"input":_vm.handleSelectCompany},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}],null,false,2029547898),model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }